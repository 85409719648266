<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline" @click="getStats();">Traffic report</div>
          <span class="subtitle-1 light-grey">
            Overview of clicks and sales per day for each influencer
          </span>
        </div>
        <v-spacer></v-spacer>

        <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" left>
          <template v-slot:activator="{ on }">
            <v-badge overlap color="primary">
              <template v-if="filtersCount > 0" v-slot:badge>{{ filtersCount }}</template>
              <v-btn
                v-on="on"
                icon
                outlined
                color="primary"
                @click="
                filtersTmp = cloneDeep(filters);
                filterDialog = true;
              ">
                <v-icon small>fas fa-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Filtrer</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :items-per-page="items.length"
          hide-default-footer
        >
          <v-progress-linear
            slot="progress"
            color="primary"
            indeterminate
          ></v-progress-linear>

          <template v-if="items.length > 0" v-slot:body="{ items }">
            <tbody>
            <tr
              v-for="item in items"
              :key="item.uuid"
            >
              <td v-for="header in headers" :key="header.text">
                <template v-if="header.text === 'Influencer'">
                  <router-link :to="{ name: 'influencer', params: { id: item.influencer.uuid } }">
                    {{ item.influencer.user.first_name + ' ' + item.influencer.user.last_name }}
                  </router-link>
                </template>
                <template v-else>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-icon color="primary" small class="mr-1">fal fa-hand-point-up</v-icon>
                        {{ getPropertyFromItemHeader(item, header.text, 'clicks') }}
                      </div>
                    </template>
                    <span>Clicks</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-icon color="primary" small>fal fa-shopping-basket</v-icon>
                        {{ getPropertyFromItemHeader(item, header.text, 'sales') }}
                      </div>
                    </template>
                    <span>Sales</span>
                  </v-tooltip>
                </template>
              </td>
            </tr>
            </tbody>
          </template>

          <template v-if="items.length === 0" v-slot:no-data>
            No results found
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="filterDialog" max-width="550px">
      <v-card>
        <v-card-title class="headline" primary-title>
          Filter report
        </v-card-title>
        <v-card-text>
          <influencer-autocomplete
            v-model="filtersTmp.influencer_uuid"
          ></influencer-autocomplete>

          <date-picker
            v-model="filtersTmp.end_date"
            label="Period start"
            cancel-button
            :reset-button="false"
            :max-date="todaysDate"
          ></date-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="filters = cloneDeep(filtersTmp); filterDialog = false;"
          >
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import search_filters_url from "@/helpers/mixins/search_filters_url";
import local_numbers from "@/helpers/filters/local_numbers";
import {mapActions} from "vuex";
import InfluencerAutocomplete from "../../../autocompletes/InfluencerAutocomplete";
import DatePicker from "@/components/common/filters/DatePicker";

export default {
  components: {
    InfluencerAutocomplete,
    DatePicker
  },
  filters: { local_numbers },
  mixins: [search_filters_url],
  data: () => ({
    filterDialog: false,
    filters: {},
    filtersTmp: {},
    stats: []
  }),
  computed: {
    filtersCount() {
      return Object.keys(this.filters).length; // Subtract 1 because "end_date is hidden
    },
    todaysDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    dates() {
      let dateArray = [];
      let currentDate = this.$moment(this.filters.end_date);
      let startDate = this.$moment(this.getStartDate());
      // let endDate = this.$moment(this.filters.end_date);

      while (currentDate >= startDate) {
        dateArray.push(this.$moment(currentDate).format('YYYY-MM-DD'));
        currentDate = this.$moment(currentDate).subtract(1, 'days');
      }

      return dateArray;
    },
    headers() {
      let headers = [
        {
          text: 'Influencer',
          sortable: false
        }
      ];

      for(let i = 0; i < this.dates.length; i++) {
        let date = this.dates[i];

        headers.push({
          text: date,
          sortable: false
        });
      }

      // {
      //   text: '',
      //       align: 'start',
      //     value: 'name',
      //     groupable: false,
      // },

      return headers;
    },
    items() {
      let items = [];

      let groupItemBy = function (array, property) {
        var hash = {},
            props = property.split('.');
        for (var i = 0; i < array.length; i++) {
          var key = props.reduce(function(acc, prop) {
            return acc && acc[prop];
          }, array[i]);
          if (!hash[key]) hash[key] = [];
          hash[key].push(array[i]);
        }
        return hash;
      };

      let influencers = Object.values(groupItemBy(this.stats, 'influencer.uuid'));

      for(let i = 0; i < influencers.length; i++) {
        let influencerStatsArray = influencers[i];
        let influencer = influencerStatsArray[0].influencer;

        let item = {
          influencer_uuid: influencer.uuid,
          influencer: influencer
        };

        for(let j = 0; j < this.dates.length; j++) {
          let date = this.dates[j];
          let stat = this.stats.filter(item => date === item.date && influencer.uuid === item.influencer.uuid);

          item[date] = stat.length > 0 ? stat[0] : null;
        }

        items.push(item);
      }

      return items;
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.setUrlSearchFilters(this.filters);

        this.$nextTick(() => {
          this.getStats();
        });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapActions("business", ["getBusinessStats"]),
    getStartDate() {
      return this.$moment(this.filters.end_date, 'YYYY-MM-DD')
        .subtract(8, "days")
        .format("YYYY-MM-DD");
    },
    getStats() {
      this.loading = true;
      this.stats = [];

      let params = this.cloneDeep(this.filters);
      params.start_date = this.getStartDate();
      params.groups = ["date", "influencer"];

      // Remove influencer uuid if empty so GQL doesn't crash
      if(! params.influencer_uuid) {
        delete params.influencer_uuid;
      }

      this.getBusinessStats(params).then(
        stats => {
          this.loading = false;
          this.stats = stats;
        },
        error => {
          console.log("error", error);
          this.setSnackError("Could not fetch data");
        }
      );
    },
    getPropertyFromItemHeader(item, header, property){
      if(item[header]) {
        return item[header][property];
      }

      return 0;
    }
  },
  created() {
    this.filters = this.getUrlSearchFilters();
    this.filters.end_date = this.$moment()
      .format("YYYY-MM-DD");

    this.getStats();
  }
};
</script>
