var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('div',[_c('div',{staticClass:"headline",on:{"click":function($event){return _vm.getStats();}}},[_vm._v("Traffic report")]),_c('span',{staticClass:"subtitle-1 light-grey"},[_vm._v(" Overview of clicks and sales per day for each influencer ")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"disabled":_vm.$vuetify.breakpoint.xsOnly,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{attrs:{"overlap":"","color":"primary"},scopedSlots:_vm._u([(_vm.filtersCount > 0)?{key:"badge",fn:function(){return [_vm._v(_vm._s(_vm.filtersCount))]},proxy:true}:null],null,true)},[_c('v-btn',_vm._g({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.filtersTmp = _vm.cloneDeep(_vm.filters);
              _vm.filterDialog = true;}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-filter")])],1)],1)]}}])},[_c('span',[_vm._v("Filtrer")])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"items-per-page":_vm.items.length,"hide-default-footer":""},scopedSlots:_vm._u([(_vm.items.length > 0)?{key:"body",fn:function(ref){
              var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.uuid},_vm._l((_vm.headers),function(header){return _c('td',{key:header.text},[(header.text === 'Influencer')?[_c('router-link',{attrs:{"to":{ name: 'influencer', params: { id: item.influencer.uuid } }}},[_vm._v(" "+_vm._s(item.influencer.user.first_name + ' ' + item.influencer.user.last_name)+" ")])]:[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary","small":""}},[_vm._v("fal fa-hand-point-up")]),_vm._v(" "+_vm._s(_vm.getPropertyFromItemHeader(item, header.text, 'clicks'))+" ")],1)]}}],null,true)},[_c('span',[_vm._v("Clicks")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("fal fa-shopping-basket")]),_vm._v(" "+_vm._s(_vm.getPropertyFromItemHeader(item, header.text, 'sales'))+" ")],1)]}}],null,true)},[_c('span',[_vm._v("Sales")])])]],2)}),0)}),0)]}}:null,(_vm.items.length === 0)?{key:"no-data",fn:function(){return [_vm._v(" No results found ")]},proxy:true}:null],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"550px"},model:{value:(_vm.filterDialog),callback:function ($$v) {_vm.filterDialog=$$v},expression:"filterDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline",attrs:{"primary-title":""}},[_vm._v(" Filter report ")]),_c('v-card-text',[_c('influencer-autocomplete',{model:{value:(_vm.filtersTmp.influencer_uuid),callback:function ($$v) {_vm.$set(_vm.filtersTmp, "influencer_uuid", $$v)},expression:"filtersTmp.influencer_uuid"}}),_c('date-picker',{attrs:{"label":"Period start","cancel-button":"","reset-button":false,"max-date":_vm.todaysDate},model:{value:(_vm.filtersTmp.end_date),callback:function ($$v) {_vm.$set(_vm.filtersTmp, "end_date", $$v)},expression:"filtersTmp.end_date"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.filters = _vm.cloneDeep(_vm.filtersTmp); _vm.filterDialog = false;}}},[_vm._v(" Filter ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }